import { createComponent, If, IntrinsicProps } from '@/common/util/templateHelpers';

import style from './index.module.scss';
import { SiteTakeover } from '@/models/Takeover';

interface TakeoverContainerProps extends IntrinsicProps {
  takeover?: SiteTakeover
}

export default createComponent<TakeoverContainerProps>('TakeoverContainer', 
  { style }, 
  function TakeoverContainer ({ className, slots }, props) {
    return (
      <div 
        className={className} 
        style={{ 
          backgroundColor: props.takeover?.bgColor || 'transparent' 
        }}
      >
        {
          If(props.takeover?.isActive, () => (
            <a 
              className='TakeoverContainer__Target' 
              href={props.takeover?.campaignHref} 
              target='_blank' 
              rel='noopener noreferrer'
            >
              <div className='TakeoverContainer__Feature'>
                <picture>
                  <source media='(max-width: 1023px)' srcSet={props.takeover.x375imgSrc} />
                  <source media='(min-width: 1024px) and (max-width: 1365px)' srcSet={props.takeover.x1024imgSrc} />
                  <source media='(min-width: 1366px) and (max-width: 1919px)' srcSet={props.takeover.x1366imgSrc} />
                  <source media='(min-width: 1920px) and (max-width: 2559px)' srcSet={props.takeover.x1920imgSrc} />
                  <source media='(min-width: 2560px)' srcSet={props.takeover.x1440imgSrc} />
                  <img src={props.takeover.x1024imgSrc} alt={props.takeover.imgAltText} />
                </picture>
              </div>
            </a>
          )).EndIf()
        }
        <div className='TakeoverContainer__Inner'>
          {props.children}
        </div>
      </div>
    );
  });
