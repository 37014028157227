import { useDrupalContext } from '@/contexts/Drupal.context';
import { Tag } from '@/util/formatters';
import { useState, useRef } from 'react';

interface UseTagsCarouselOptions {
  lazyLoad?: boolean
}

export function useTagsCarousel (options: UseTagsCarouselOptions) {
  const { tags: allTags } = useDrupalContext();
  const [ tags, setTags ] = useState<Tag[]>(allTags.slice(0, 12));
  const carouselRef = useRef(null);
  const hasFetchedTags = useRef(false);

  const fetchNextTags = async () => {
    if (hasFetchedTags.current) return true;
    hasFetchedTags.current = true;

    setTags(() => [ ...allTags ]);
    return true;
  };

  return {
    tags,
    fetchNextTags,
    elRef: carouselRef,
  };
}
