import { useRef } from 'react';
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { createComponent, If, IntrinsicProps } from '@/common/util/templateHelpers';
import { IconFA } from '@/common/components';
import { Button } from '@/common/components/controls';

import style from './index.module.scss';

const SHAKE_ANIM_DURATION = 300;

interface CardCarouselProps extends IntrinsicProps {
  onNext?: () => void
}

export default createComponent<CardCarouselProps>('CardCarousel', { style }, function CardCarousel ({ className }, props) {
  const wrapperElRef = useRef<HTMLDivElement>(null);
  const innerElRef = useRef<HTMLDivElement>(null);
  const controlsElRef = useRef<HTMLDivElement>(null);
  const shakeTimeoutRef = useRef<NodeJS.Timeout>(null);

  const playReachedEndAnim = (direction: 'next' | 'prev') => {
    const wrapperEl = wrapperElRef.current;
    if (!wrapperEl) return;

    wrapperEl.classList.add(`--shake__${direction}`);

    if (shakeTimeoutRef.current) clearTimeout(shakeTimeoutRef.current);
    setTimeout(() => {
      wrapperEl.classList.remove('--shake__next', '--shake__prev');
    }, SHAKE_ANIM_DURATION);
  };

  const next = () => {
    if (props.onNext) props.onNext();

    const innerEl = innerElRef.current;
    if (!innerEl) return;

    const wrapperEl = wrapperElRef.current;
    if (!wrapperEl) return;
    if (wrapperEl.classList.contains('--shake__next')) return;
    if (wrapperEl.classList.contains('--shake__prev')) return;

    const slideEl = innerEl.querySelector('.CardCarousel__Card.--current');
    if (!slideEl) return;

    const slides = Array.from(innerEl.querySelectorAll('.CardCarousel__Card'));
    const index = slides.indexOf(slideEl);
    if (!slides[ index + 1 ]) return;
    if (!slides[ index + 3 ]) return playReachedEndAnim('next');

    slides.forEach((el) => el.classList.remove('--current'));
    slides[ index + 1 ].classList.add('--current');

    controlsElRef.current.classList.remove('--firstSlide');
  };

  const prev = () => {
    const innerEl = innerElRef.current;
    if (!innerEl) return;

    const wrapperEl = wrapperElRef.current;
    if (!wrapperEl) return;
    if (wrapperEl.classList.contains('--shake__next')) return;
    if (wrapperEl.classList.contains('--shake__prev')) return;

    const slideEl = innerEl.querySelector('.CardCarousel__Card.--current');
    if (!slideEl) return;

    const slides = Array.from(innerEl.querySelectorAll('.CardCarousel__Card'));
    const index = slides.indexOf(slideEl);
    if (!slides[ index - 1 ]) return playReachedEndAnim('next');
    if (!slides[ index - 2 ]) controlsElRef.current.classList.add('--firstSlide');

    slides.forEach((el) => el.classList.remove('--current'));
    slides[ index - 1 ].classList.add('--current');
  };
  
  return (
    <div className={className}>
      <div className='CardCarousel__Wrapper' ref={wrapperElRef}>
        <div ref={innerElRef} className='CardCarousel__Inner'>
          {props.children}
        </div>
      </div>
      <div ref={controlsElRef} className={'CardCarousel__Controls --firstSlide'}>
        <Button primary onClick={() => prev()} ariaLabel='Show Previous'>
          <IconFA icon={faChevronLeft} />
        </Button>
        <Button primary onClick={() => next()} ariaLabel='Show Next'>
          <IconFA icon={faChevronRight} />
        </Button>
      </div>
    </div>
  );
});

interface CardProps extends IntrinsicProps {
  placeholder?: boolean
}

const classStates = [
  'placeholder',
];

export const Card = createComponent<CardProps>('CardCarousel__Card', { style, classStates }, function Card ({ className }, props) {
  return (
    <div className={className} aria-hidden={props.placeholder}>
      {
        If(!props.placeholder, () => (
          <div>{props.children}</div>
        )).EndIf()
      }
    </div>
  );
});
