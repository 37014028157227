import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import { Tag } from '@/util/formatters';
import Image from 'next/image';

import style from './index.module.scss';
import { faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { IconFA } from '@/common/components';
import Link from 'next/link';

interface TaxonomyTileProps extends IntrinsicProps {
  tag: Tag
}

export default createComponent<TaxonomyTileProps>('TaxonomyTile',
  { style }, 
  function TaxonomyTile ({ className }, props) {
    
    return (
      <Link href={props.tag.path}>
        <a className={'GameTile AgGameTile ' + className}>
          <div className='GameTile__Preview'>
            <Image
              src={props.tag.thumbnailUrl}
              alt={props.tag.name + ' games thumbnail image'}
              width={180}
              height={117}
            />
          </div>
          <span>{props.tag.name} <IconFA icon={faChevronRight} /></span>
        </a>
      </Link>
    );
  });