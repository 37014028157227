/* #region Imports */

// First-party
import { createComponent, createPage, For, If, IntrinsicProps, Slot } from '@/common/util/templateHelpers';
import { AdContainer, AdContainerSize, Block, Container, Content, Flex, GameTile, RouterLink } from '@/common/components';
import Meta, { MetaProps } from '@/common/components/head/Meta';

// Local
import DynamicLayout from '@/layouts/DynamicLayout';
import SiteTakeoverBlock from '@/components/SiteTakeover';
import { GameBasic, Tag } from '@/util/formatters';
import { useAuthStore } from '@/stores/auth';
import TakeoverModel, { CarouselTakeover, SiteTakeover } from '@/models/Takeover';
import HomePageGamesModel from '@/models/HomePageGames';
import BlockPod from '@/components/BlockPod';
import Yolla, { YollaType, YollaSubType } from '@/components/Yolla';
import { SlideSingle } from '@/components/FluidCarousel';
import BlogPostComponent from '@/components/BlogPost';
import FluidGameCarousel, { DesktopGameSlides, FeaturedGameSlides, MobileGameSlides } from '@/components/FluidGameCarousel';
import { useGameCarousel } from '@/hooks/useGameCarousel';

import style from './index.module.scss';
import TaxonomyTile from '@/components/TaxonomyTile';
import CardCarousel, { Card } from '@/components/CardCarousel';
import DeveloperPod from '@/components/DeveloperPod';
import { useEffect, useRef, useState } from 'react';
import BlogModel, { BlogPost } from '@/models/Blog';
import DeveloperSpotlightModel, { DevSpotlight } from '@/models/DeveloperSpotlight';
import { useTagsCarousel } from '@/hooks/useTagsCarousel';

/* #endregion */



/* #region Types & Data */

interface IndexPageProps extends IntrinsicProps {
  takeoverGames: GameBasic[]
  mostAddictingGames: GameBasic[]
  siteTakeover: SiteTakeover
  carouselTakeover: CarouselTakeover
}

/* #endregion */



/* #region Component Logic */

function useIndexPage () {
  const metaProps: Partial<MetaProps> = {
    ldjson: [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        'url': 'https://www.addictinggames.com',
        'name': 'Addicting Games',
        'logo': 'https://www.addictinggames.com/images/logo.svg',
        'email': 'support@addictinggameshelp.zendesk.com',
        'sameAs': [
          'https://www.facebook.com/AddictingGames'
        ],
        'contactPoint': [
          {
            '@type': 'ContactPoint',
            'contactType': 'customer service',
            'url': 'https://addictinggameshelp.zendesk.com/hc/en-us/requests/new',
            'email': 'support@addictinggameshelp.zendesk.com'
          }
        ]
      }
    ]
  };

  // Return value
  return {
    metaProps
  };
}

function useDeveloperSpotlight () {
  const [ spotlight, setSpotlight ] = useState<DevSpotlight>(null);
  const [ games, setGames ] = useState<DevSpotlight[ 'games' ]>([]);
  const [ placeholderGames, setPlaceholderGames ] = useState<DevSpotlight[ 'games' ]>([]);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState<Error>();
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (spotlight && spotlight.games?.length) return;
    if (!firstRenderRef.current) return;

    (async () => {
      setLoading(true);
      firstRenderRef.current = false;

      try {
        const result = await DeveloperSpotlightModel.getSpotlight();
        setSpotlight(() => result);
        setGames(() => [ ...result.games, ...result.games ]);
        setPlaceholderGames(() => result.games.slice(-2));
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    })();
  });

  const indexRef = useRef(0);
  const onNext = async () => {
    const nextGames = [ ...games ];
    nextGames.push(games[ indexRef.current ]);
    setGames(() => nextGames);
    indexRef.current++;
  };

  return {
    spotlight,
    games,
    placeholderGames,
    loading,
    error,
    onNext
  };
}

function useBlogPosts () {
  const [ posts, setPosts ] = useState<BlogPost[]>([]);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState<Error>();
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (posts.length) return;
    if (!firstRenderRef.current) return;

    (async () => {
      setLoading(true);
      firstRenderRef.current = false;

      try {
        const posts = await BlogModel.listAll(3, 0);
        setPosts(posts);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    })();
  });

  return {
    posts,
    loading,
    error,
  };
}

/* #endregion */



/* #region Component */

interface IndexPageBannerAdProps extends IntrinsicProps {
  isVisible: boolean,
  num: number,
}

const IndexPageBannerAd = createComponent<IndexPageBannerAdProps>('IndexPageBannerAd', {}, function IndexPageBannerAd ({}, props) {
  return (
    <>
      {
        If(props.isVisible, () => (
          <Block>
            <Flex>
              {/* Mobile ads */}
              <AdContainer size={AdContainerSize.MOBILE_LEADERBOARD} className='--hideDesktop'>
                <Yolla type={YollaType.MOBILE_LEADERBOARD} subType={YollaSubType.MOBILE_LEADERBOARD} num={props.num} />
              </AdContainer>
              {/* Desktop ads */}
              <AdContainer size={AdContainerSize.LEADERBOARD} className='--hideMobile'>
                <Yolla type={YollaType.DESKTOP_LEADERBOARD} subType={YollaSubType.DESKTOP_LEADERBOARD} num={props.num} />
              </AdContainer>
            </Flex>
          </Block>
        )).EndIf()
      }
    </>
  );
});


const homePageDesktop = {
  ads: [
    { type: 'bottom_rail' },
    { type: 'standard_iab_head1', selector: '.ad_728x90_1' },
    { type: 'standard_iab_cntr1', selector: '.ad_728x90_2' },
    { type: 'standard_iab_cntr3', selector: '.ad_728x90_3' },
  ],
};

const homePageMobile = {
  ads: [
    { type: 'bottom_rail' },
    { type: 'standard_iab_head1', selector: '.ad_320x50_1' },
    { type: 'standard_iab_cntr1', selector: '.ad_320x50_2' },
    { type: 'standard_iab_cntr3', selector: '.ad_320x50_3' },
  ],
};


export default createPage<IndexPageProps>('IndexPage', { style }, function IndexPage ({ className }, props) {
  const [ authState ] = useAuthStore();
  const { metaProps } = useIndexPage();


  useEffect(() => {
    // Function to detect if the device is mobile
    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

    const checkRampDefined = setInterval(() => {
      if (window.ramp && typeof window.ramp.spaAds === 'function') {
        // Clear the interval once ramp and spaAds are defined
        clearInterval(checkRampDefined);
        
        if (isMobile) {
          window.ramp.que.push(() => {
            window.ramp.spaAds(homePageMobile);
          });
        } else {
          window.ramp.que.push(() => {
            window.ramp.spaAds(homePageDesktop);
          });
        }
      }
    }, 100); // Check every 100 milliseconds
    
  }, []); // Empty dependency array ensures this runs once on mount




  const mostAddicting = useGameCarousel({
    loop: true,
    initialGames: {
      mobile: props.mostAddictingGames,
      desktop: props.mostAddictingGames
    },
    fetchGames: {
      mobile: (page) => HomePageGamesModel.getMostAddictingGames(12, 12 * page),
      desktop: (page) => HomePageGamesModel.getMostAddictingGames(24, 24 * page),
    }
  });

  const topGames = useGameCarousel({
    loop: true,
    lazyLoad: true,
    fetchGames: {
      mobile: (page) => HomePageGamesModel.getHighestRatedGames(12, 12 * page, true),
      desktop: (page) => HomePageGamesModel.getHighestRatedGames(24, 24 * page),
    }
  });

  const newGames = useGameCarousel({
    loop: true,
    lazyLoad: true,
    fetchGames: {
      mobile: (page) => HomePageGamesModel.getNewGames(12, 12 * page, true),
      desktop: (page) => HomePageGamesModel.getNewGames(24, 24 * page),
      // mobile: (page) => HomePageGamesModel.getNewGamesQueue(5, 5 * page),
      // desktop: (page) => HomePageGamesModel.getNewGamesQueue(5, 5 * page),
    }
  });

  const trendingGames = useGameCarousel({
    loop: true,
    lazyLoad: true,
    fetchGames: {
      mobile: (page) => HomePageGamesModel.getTrendingGames(12, 12 * page),
      desktop: (page) => HomePageGamesModel.getTrendingGames(24, 24 * page),
    }
  });


  const classicGames = useGameCarousel({
    loop: true,
    lazyLoad: true,
    fetchGames: {
      mobile: (page) => HomePageGamesModel.getClassicGames(12, 12 * page),
      desktop: (page) => HomePageGamesModel.getClassicGames(24, 24 * page),
    }
  });


  const tags = useTagsCarousel({ 
    lazyLoad: true 
  });

  const spotlight = useDeveloperSpotlight();
  
  const blog = useBlogPosts();

  const shouldSuppressAds = authState.ready && authState.user?.hasGamePass;

  return (
    <DynamicLayout className={className}>
      <Meta 
        titleTag='Games - Free Online Games at Addicting Games'
        title='Games - Free Online Games at Addicting Games'
        {...metaProps}
      />

      {/* Most Addicting Site Takeover */}
      {
        If(props.takeoverGames.length >= 21 && props.siteTakeover?.isActive && !shouldSuppressAds, () => (
          <SiteTakeoverBlock
            takeover={props.siteTakeover}
            mobileGames={props.takeoverGames}
            initialGames={props.takeoverGames}
            fetchGames={() => {
              return HomePageGamesModel.getTakeoverQueue(24, 0);
            }}
          >
            <Slot name='title'>
              <h2>Most Addicting</h2>
            </Slot>
            <Slot name='aside'><RouterLink href={'/most-addicting-games'}>View More</RouterLink></Slot>
          </SiteTakeoverBlock>
        )).EndIf()
      }

      <Container className='--marMd0__t'>
        {/* Most Addicting Carousel */}
        {
          If(!props.siteTakeover?.isActive || shouldSuppressAds, () => (
            <BlockPod>
              <Slot name='title'>
                <h2>Most Addicting Games</h2>
                <div ref={mostAddicting.elRef} />
              </Slot>
              <Slot name='aside'><RouterLink href={'/most-addicting-games'}>View More</RouterLink></Slot>
              <FluidGameCarousel
                onNext={mostAddicting.fetchNextGames}
                ready
                loading={mostAddicting.loading}
                height='featured'
              >
                <Slot name='desktop'>
                  <FeaturedGameSlides games={mostAddicting.games} />
                </Slot>
              </FluidGameCarousel>
            </BlockPod>
          )).EndIf()
        }

        {/* Category Carousel */}
        <BlockPod>
          <Slot name='title'>
            <h2>Categories</h2>
          </Slot>
          <Slot name='aside'><RouterLink href={'/all-categories'}>View More</RouterLink></Slot>
          <FluidGameCarousel 
            onNext={tags.fetchNextTags}
            ready
            height='single'
          >
            <Slot name='desktop'>
              {
                For(tags.tags, (tag: Tag, i) => (
                  <SlideSingle key={i} className='--oneRow' style={{ paddingRight: '16px' }}>
                    <TaxonomyTile tag={tag} />
                  </SlideSingle>
                ))
              }
            </Slot>
          </FluidGameCarousel>
        </BlockPod>

        {/* Banner Ad */}
        <IndexPageBannerAd isVisible={authState.ready && !authState.user?.hasGamePass} num={1} />

        {/* Developer Spotlight Carousel */}
        {
          If(!spotlight.error && spotlight.spotlight?.isActive && spotlight.games.length, () => (
            <BlockPod>
              <Slot name='title'>
                <h2>Developer Spotlight</h2>
              </Slot>
              {
                If(!spotlight.loading, () => (
                  <BlockPod>
                    <CardCarousel onNext={spotlight.onNext}>
                      <Card className='--current'>
                        <DeveloperPod 
                          spotlight={spotlight.spotlight}
                          game={spotlight.placeholderGames[ 0 ]}
                        />
                      </Card>
                      <Card>
                        <DeveloperPod 
                          spotlight={spotlight.spotlight}
                          game={spotlight.placeholderGames[ 1 ]}
                        />
                      </Card>
                      {
                        For(spotlight.games, (game: DevSpotlight[ 'games' ][ 0 ], i) => (
                          <Card key={game.nid + '_' + i}>
                            <DeveloperPod 
                              spotlight={spotlight.spotlight}
                              game={game}
                            />
                          </Card>
                        ))
                      }
                    </CardCarousel>
                  </BlockPod>
                ))
                  .Else(() => (
                    <BlockPod>
                      <CardCarousel>
                        <Card placeholder />
                        <Card placeholder />
                        <Card placeholder />
                      </CardCarousel>
                    </BlockPod>
                  ))
                  .EndIf()
              }
            </BlockPod>
          )).EndIf()
        }


  {/* Classic Games */}
  <BlockPod>
          <Slot name='title'>
            <h2>Classic Games</h2>
            <div ref={classicGames.elRef} />
          </Slot>
          <Slot name='aside'><RouterLink href={'/tag/classic-games'}>View More</RouterLink></Slot>
          <FluidGameCarousel
            onNext={classicGames.fetchNextGames}
            ready={classicGames.ready}
            loading={classicGames.loading}
            height='default'
          >
            <Slot name='mobile'>
              <MobileGameSlides key='trendingGamesMobile' games={classicGames.games} />
            </Slot>
            <Slot name='desktop'>
              <DesktopGameSlides key='trendingGamesDesktop' games={classicGames.games} />
            </Slot>
          </FluidGameCarousel>
        </BlockPod>


        {/* New Games Carousel */}
        <BlockPod>
          <Slot name='title'>
            <h2>New Games</h2>
            <div ref={newGames.elRef} />
          </Slot>
          <Slot name='aside'><RouterLink href={'/new-games'}>View More</RouterLink></Slot>
          <FluidGameCarousel
            onNext={newGames.fetchNextGames}
            ready={newGames.ready}
            loading={newGames.loading}
            height='default'
          >
            <Slot name='mobile'>
              <MobileGameSlides key='newGamesMobile' games={newGames.games} />
            </Slot>
            <Slot name='desktop'>
              <DesktopGameSlides key='newGamesDesktop' games={newGames.games}>
                {
                  If(props.carouselTakeover?.isActive && !shouldSuppressAds, () => (
                    <Slot name='ad'>
                      <a href={props.carouselTakeover.campaignHref} target='_blank' rel='noreferrer noopener'>
                        <img
                          src={props.carouselTakeover.imgSrc}
                          alt={props.carouselTakeover.imgAltText}
                          style={{ width: 'auto', height: '250px', maxWidth: 'none' }}
                        />
                      </a>
                    </Slot>
                  )).EndIf()
                }
              </DesktopGameSlides>
            </Slot>
          </FluidGameCarousel>
        </BlockPod>

        {/* Banner Ad */}
        <IndexPageBannerAd isVisible={authState.ready && !authState.user?.hasGamePass} num={2} />

        {/* Top 100 Carousel */}
        <BlockPod>
          <Slot name='title'>
            <h2>Top 100 Games</h2>
            <div ref={topGames.elRef} />
          </Slot>
          <Slot name='aside'><RouterLink href={'/top-games'}>View More</RouterLink></Slot>
          <FluidGameCarousel
            onNext={topGames.fetchNextGames}
            ready={topGames.ready}
            loading={topGames.loading}
            height='default'
          >
            <Slot name='mobile'>
              <MobileGameSlides key='top100mobile' games={topGames.games} />
            </Slot>
            <Slot name='desktop'>
              <DesktopGameSlides key='top100desktop' games={topGames.games} />
            </Slot>
          </FluidGameCarousel>
        </BlockPod>

        {/* Banner Ad */}
        <IndexPageBannerAd isVisible={authState.ready && !authState.user?.hasGamePass} num={3} />

        {/* Trending */}
        <BlockPod>
          <Slot name='title'>
            <h2>Trending Games</h2>
            <div ref={trendingGames.elRef} />
          </Slot>
          <Slot name='aside'><RouterLink href={'/trending-games'}>View More</RouterLink></Slot>
          <FluidGameCarousel
            onNext={trendingGames.fetchNextGames}
            ready={trendingGames.ready}
            loading={trendingGames.loading}
            height='default'
          >
            <Slot name='mobile'>
              <MobileGameSlides key='trendingGamesMobile' games={trendingGames.games} />
            </Slot>
            <Slot name='desktop'>
              <DesktopGameSlides key='trendingGamesDesktop' games={trendingGames.games} />
            </Slot>
          </FluidGameCarousel>
        </BlockPod>




        {/* AG Blog */}
        {
          If(!blog.error, () => (
            <BlockPod>
              <Slot name='title'><h2>AG Blog</h2></Slot>
              <Slot name='aside'><RouterLink href={'/blog'}>View More</RouterLink></Slot>
              {
                If(!blog.loading, () => (
                  <Flex gap0 container collapseMobile>
                    {
                      For(blog.posts, (post: BlogPost) => (
                        <Flex key={post.nid} fit>
                          <BlogPostComponent href={'/blog' + post.path} imageSrc={post.thumbnailUrl}>
                            <Slot name='title'>
                              <span dangerouslySetInnerHTML={{ __html: post.title }} />
                            </Slot>
                            <Slot name='description'>
                              <p>
                                {post.description}
                              </p>
                            </Slot>
                          </BlogPostComponent>
                        </Flex>
                      ))
                    }
                  </Flex>
                ))
                  .Else(() => (
                    <Flex gap0 container collapseMobile>
                      <Flex fit>
                        <BlogPostComponent href={'/blog'} imageSrc={'/images/blogPlaceholder.svg'}>
                          <Slot name='title'>
                            <p />
                          </Slot>
                          <Slot name='description'>
                            <p />
                          </Slot>
                        </BlogPostComponent>
                      </Flex>
                      <Flex fit>
                        <BlogPostComponent href={'/blog'} imageSrc={'/images/blogPlaceholder.svg'}>
                          <Slot name='title'>
                            <p />
                          </Slot>
                          <Slot name='description'>
                            <p />
                          </Slot>
                        </BlogPostComponent>
                      </Flex>
                      <Flex fit>
                        <BlogPostComponent href={'/blog'} imageSrc={'/images/blogPlaceholder.svg'}>
                          <Slot name='title'>
                            <p />
                          </Slot>
                          <Slot name='description'>
                            <p />
                          </Slot>
                        </BlogPostComponent>
                      </Flex>
                    </Flex>
                  ))
                  .EndIf()
              }
            </BlockPod>
          )).EndIf()
        }

        {/* SEO Blurb */}
        <BlockPod centerHeader>
          <Slot name='title'>
            <h1>Free online games</h1>
          </Slot>
          <Content>
            <p>
              Addicting Games features the latest and best free online games. You can enjoy playing fun games without interruptions, no downloads, intrusive ads, or pop-ups. Just load up your favorite games instantly in your web browser and enjoy the experience. You can play our games on desktop or mobile devices. That includes everything from desktop PCs, laptops, and Chromebooks, to the latest smartphones and tablets from Apple and Android. 
            </p>
            <p>
              There are plenty of online multiplayer games with active communities on AddictingGames. You can find many of the best free multiplayer titles on our .io games page. In these games, you can play with your friends online and with other people from around the world, no matter where you are.
            </p>
          </Content>
        </BlockPod>

        <br />
      </Container>
    </DynamicLayout>
  );
});

/* #endregion */



/* #region Server Props */

export async function getServerSideProps () {
  const results = await Promise.all([
    HomePageGamesModel.getTakeoverQueue(24),
    HomePageGamesModel.getMostAddictingGames(24, 0),
    TakeoverModel.getSiteTakeover(),
    TakeoverModel.getCarouselTakeover(),
  ]);

  const [
    takeoverGames,
    mostAddictingGames,
    siteTakeover,
    carouselTakeover,
  ] = results;

  const props: IndexPageProps = {
    takeoverGames,
    mostAddictingGames,
    siteTakeover,
    carouselTakeover,
  };

  return {
    props,
  };
}

/* #endregion */
