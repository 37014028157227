import { Flex } from '@/common/components';
import Image from 'next/image';
import { IntrinsicProps, createComponent } from '@/common/util/templateHelpers';

import style from './index.module.scss';
import { ButtonLink } from '@/common/components/controls';
import { DevSpotlight } from '@/models/DeveloperSpotlight';

interface DeveloperPodProps extends IntrinsicProps {
  spotlight: DevSpotlight
  game: DevSpotlight[ 'games' ][ 0 ]
}

export default createComponent<DeveloperPodProps>('DeveloperPod', { style }, function DeveloperPod ({ className }, props) {
  return (
    <Flex className={className}>
      <Flex>
        <Image
          src={props.game.thumbnailUrl}
          alt='Developer Image'
          width={485}
          height={285}
          layout="fixed"
        />
        <div>
          <ButtonLink medium primary href={props.game.path}>
            <span dangerouslySetInnerHTML={{ __html: `Play ${props.game.title}` }}/>
            <small>by {props.spotlight.developer}</small>
          </ButtonLink>
        </div>
      </Flex>
      <Flex directionColumn>
        <Flex alignCenter>
          <Flex alignCenter>
            <Image
              src={props.spotlight.developerLogoUrl}
              alt={`Developer ${props.spotlight.developer} logo`}
              width={52}
              height={52}
              layout="fixed"
            />
          </Flex>
          <Flex directionColumn className='--padMd0__l DeveloperPod__About'>
            <p><strong>{props.spotlight.developer}</strong></p>
            {/* <p>??? games</p> */}
            {/* <p>??? plays</p> */}
          </Flex>
        </Flex>
        <hr />
        <p>
          {props.game.developerDescription}
        </p>
      </Flex>
    </Flex>
  );
});
