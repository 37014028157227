import Image from 'next/image';
import { ASPECT_21_9, Content, Flex, IconFA, RouterLink, Spacer } from '@/common/components';
import { createComponent, If, IntrinsicProps } from '@/common/util/templateHelpers';

import style from './index.module.scss';
import { Button } from '@/common/components/controls';
import Link from 'next/link';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface BlogPostProps extends IntrinsicProps {
  imageSrc?: string
  href: string
}

export default createComponent<BlogPostProps>('BlogPost', 
  { style }, 
  function BlogPost ({ className, slots }, props) {
    return (
      <Link href={props.href}>
        <div className={className}>
          <Flex tall directionColumn>
            {
              If(props.imageSrc, () => (
                <Flex className='--padMd0__b'>
                  <div className='BlogPost__Image'>
                    <Image src={props.imageSrc} layout='fill' alt='Blog Post Thumbnail' />
                    <Spacer spacer={ASPECT_21_9} />
                  </div>
                </Flex>
              ))
                .EndIf()
            }
            <Flex fit>
              <Content>
                <h2><strong>{slots.title}</strong></h2>
                {slots.description}
              </Content>
            </Flex>
            <Flex className='--padMd0__t'>
              {
                If(props.imageSrc, () => (
                  <Button primary><span>Read More</span></Button>
                ))
                  .Else(() => (
                    <RouterLink href={props.href}>
                      Read More
                      <IconFA icon={faArrowRight} />
                    </RouterLink>
                  ))
                  .EndIf()
              }
            </Flex>
          </Flex>
        </div>
      </Link>
    );
  });
