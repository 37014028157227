import axios from '@/apis/drupal/instance';

interface DrupalDevSpotlightData {
  field_developer_name: string
  field_developer_logo: string
  items: {
    nid: string
    title: string
    field_thumbnail: string
    field_video_thumbnail: string
    view_node: string
    published_at: string
    rating: string
    likes: string
    dislikes: string
    vote_total: string
    field_developer_description: string
  }[]
}

export interface DevSpotlight {
  developer: string
  developerLogoUrl: string
  isActive?: boolean
  games: {
    nid: string
    title: string
    path: string
    thumbnailUrl: string
    developerDescription: string
  }[]
}

export default class DeveloperSpotlightModel {
  static format (data: DrupalDevSpotlightData[ 'items' ][ 0 ]): DevSpotlight[ 'games' ][ 0 ] {
    const thumbnailUrl = data.field_thumbnail 
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + data.field_thumbnail 
      : '/images/emptyThumbnail.svg';

    return {
      nid: data.nid,
      title: data.title,
      thumbnailUrl,
      path: data.view_node || '/',
      developerDescription: data.field_developer_description || '',
    };
  }

  static async getSpotlight (): Promise<DevSpotlight> {
    let result: any;

    try {
      result = await axios.request({
        method: 'get',
        url: '/rest/block/spotlight-games',
      });
    } catch (error) {
      throw error;
    }

    const rawData = result[ 0 ];

    if (!rawData || !rawData.field_start_date || !rawData.field_end_date || !rawData.items.length) {
      return {
        isActive: false,
        developer: '',
        developerLogoUrl: '',
        games: [],
      };
    }

    const startDate = new Date(rawData.field_start_date || Date.now());
    const endDate = new Date(rawData.field_end_date || Date.now());
    const isActive = (startDate.getTime() <= Date.now()) && (endDate.getTime() >= Date.now());

    return {
      isActive,
      developer: rawData.field_developer_name,
      developerLogoUrl: process.env.NEXT_PUBLIC_IMAGE_DOMAIN + rawData.field_developer_logo,
      games: rawData.items.map((item) => DeveloperSpotlightModel.format(item)),
    };
  }
}
