import { createComponent } from '@/common/util/templateHelpers';
import { App, Layout } from '@/common/components';

import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import { useAuthStore } from '@/stores/auth';

import style from './index.module.scss';

export default createComponent('DynamicLayout', { style }, function DynamicLayout ({ className }, props) {
  const [ authState ] = useAuthStore();

  return (
    <Layout className={className}>
      <App.Header>
        <AppHeader />
      </App.Header>

      <App.Main>
        {props.children}
      </App.Main>

      <App.Footer>
        <AppFooter />
      </App.Footer>
    </Layout>
  );
});
