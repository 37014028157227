import axios from '@/apis/drupal/instance';
import { NumericId, Id } from '@/util/formatters';
import truncateTextToWord from '@/common/util/truncateText';
import { toQuery } from '@/common/util/networkRequest';
import { parse } from '@/util/stripHtml';

const POST_LIMIT = 50;
const COMMENT_LIMIT = 50;
const DESCRIPTION_LENGTH = 150;

interface DrupalBlogPostData {
  id: string
  attributes: {
    drupal_internal__nid: string
    title: string
    created: string
    path: {
      alias: string
    }
    body: {
      value: string
      processed: string
    }
  }
  relationships: {
    field_blog_thumb: {
      data: {
        id: string
      }
    }
  }
}

export interface BlogPost {
  nid: NumericId,
  uid: Id,
  title: string,
  created: string,
  path: string,
  body: string,
  description: string,
  thumbnailUrl: string
}

interface DrupalBlogPostCommentData {
  id: string
  attributes: {
    drupal_internal__nid: string
    created: string
    comment_body: {
      processed: string
    }
  }
}

interface DrupalBlogPostCommentIncludedData {
  id: string
  attributes: {
    display_name: string
    drupal_internal__uid: string
    path: {
      alias: string
    }
  }
}

export interface BlogPostComment {
  nid: NumericId,
  uid: Id,
  body: string
  created: string
  user: {
    displayName: string
    path: string
    userPicture: string
  }
}

const blogQuery = {
  fields: {
    'node--blog': [
      'drupal_internal__nid',
      'title',
      'created',
      'path',
      'body',
      'field_blog_thumb'
    ],
  },
  filter: {
    'status': 1,
  },
  include: [
    'field_blog_thumb'
  ],
  sort: '-created',
};

const commentQuery = {
  fields: {
    'comment--comment_node_blog': [
      'drupal_internal__nid',
      'created',
      'subject',
      'comment_body',
      'uid',
    ]
  },
  filter: {
    'status': 1,
  },
  include: [
    'uid',
  ],
  sort: '-created',
};

export default class BlogModel {
  static formatPost (postData: DrupalBlogPostData, thumbnailUrl: string): BlogPost {
    const body = postData.attributes?.body?.processed || '';
    const description = truncateTextToWord(parse(body).text, DESCRIPTION_LENGTH);
  
    thumbnailUrl = thumbnailUrl
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + thumbnailUrl
      : '/images/blogPlaceholder.svg';
  
    return {
      nid: parseInt(postData.attributes.drupal_internal__nid),
      uid: postData.id,
      title: postData.attributes.title,
      created: postData.attributes.created,
      path: postData.attributes.path?.alias,
      body,
      thumbnailUrl,
      description
    };
  }

  static formatComment (commentData: DrupalBlogPostCommentData, includedData: DrupalBlogPostCommentIncludedData): BlogPostComment {
    return {
      nid: parseInt(commentData.attributes.drupal_internal__nid),
      uid: commentData.id,
      body: parse(commentData.attributes.comment_body.processed).text,
      created: commentData.attributes.created,
      user: {
        displayName: includedData?.attributes?.display_name || 'unknown',
        path: includedData?.attributes?.path?.alias || 'unknown',
        userPicture: '',
      }
    };
  }

  static async listAll (limit = POST_LIMIT, offset = 0): Promise<BlogPost[]> {
    let result: { data: DrupalBlogPostData[], included: any[] };

    try {
      result = await axios.request({
        method: 'get',
        url: toQuery('/jsonapi/node/blog', {
          ...blogQuery,
          page: {
            limit,
            offset,
          }
        }),
      });
    } catch (error) {
      throw error;
    }

    return result.data.map((post) => BlogModel.formatPost(
      post, 
      result.included.find(
        (result => result.id === post?.relationships?.field_blog_thumb?.data?.id)
      )?.attributes?.uri?.url
    ));
  }

  static async getById (id: Id): Promise<BlogPost | { error: any }> {
    let result: { data: DrupalBlogPostData };

    try {
      result = await axios.request({
        method: 'get',
        url: `/jsonapi/node/blog/${id}`,
      });
    } catch (error) {
      return { error: JSON.stringify(error) };
    }

    return BlogModel.formatPost(result.data, undefined);
  }

  static async getCommentsForId (id: Id, limit = COMMENT_LIMIT, offset = 0): Promise<BlogPostComment[] | { error: any }> {
    let result: { 
      data: DrupalBlogPostCommentData[],
      included: DrupalBlogPostCommentIncludedData[]
    };

    try {
      result = await axios.request({
        method: 'get',
        url: toQuery('/jsonapi/comment/comment_node_blog', {
          ...commentQuery,
          filter: {
            'entity_id.id': {
              value: id
            }
          },
          page: {
            limit,
            offset,
          }
        }),
      });
    } catch (error) {
      return { error: JSON.stringify(error) };
    }

    return result.data.map((post, i) => BlogModel.formatComment(post, result.included[ i ]));
  }
}
