import { createComponent, If, IntrinsicProps } from '@/common/util/templateHelpers';

import style from './index.module.scss';
import { useEffect, useState } from 'react';
import { GameBasic } from '@/util/formatters';
import AgGameTile from '@/components/AgGameTile';
import { Flex, Loader } from '@/common/components';
import { logErrorEvent } from '@/common/util/logEvent';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import ensureMinimum from '@/util/ensureMinimum';

interface GameTileMasonryProps extends IntrinsicProps {
  mobileGames: GameBasic[]
  initialGames: GameBasic[]
  onReady?: () => void
}

export default createComponent<GameTileMasonryProps>('GameTileMasonry', 
  { style }, 
  function GameTileMasonry ({ className, slots }, props) {
    const [ games ] = useState<GameBasic[]>(props.initialGames);
    const breakpoint = useBreakpoint({ onReady: props.onReady });
    
    return (
      <div className={className}>
        {
          If(breakpoint === 'mobile', () => (
            <div className='GameTileMasonry__Col'>
              <div className='GameTileMasonry__Row'>
                <AgGameTile showLabel game={props.mobileGames[ 0 ]} className='--size2x' />
              </div>
              <div className='GameTileMasonry__Row'>
                <AgGameTile showLabel game={props.mobileGames[ 1 ]} />
                <AgGameTile showLabel game={props.mobileGames[ 2 ]} />
              </div>
              <div className='GameTileMasonry__Row'>
                <AgGameTile showLabel game={props.mobileGames[ 3 ]} />
                <AgGameTile showLabel game={props.mobileGames[ 4 ]} />
              </div>
            </div>
          ))
            .ElseIf(breakpoint === 'small', () => (
              <div className='GameTileMasonry__Col'>
                <div className='GameTileMasonry__Row'>
                  <AgGameTile showLabel game={props.initialGames[ 0 ]} />
                  <AgGameTile showLabel game={props.initialGames[ 1 ]} />
                  <AgGameTile showLabel game={props.initialGames[ 2 ]} />
                </div>
                <div className='GameTileMasonry__Row'>
                  <AgGameTile showLabel game={props.initialGames[ 3 ]} />
                  <AgGameTile showLabel game={props.initialGames[ 4 ]} />
                  <AgGameTile showLabel game={props.initialGames[ 5 ]} />
                </div>
              </div>
            ))
            .Else(() => (
              <>
                {
                  If(!games.length, () => (
                    <>
                      <Flex wide className='--relative'>
                        <Flex center className='--padMd3'>
                          <Loader />
                        </Flex>
                      </Flex>
                    </>
                  ))
                    .Else(() => (
                      <>
                        <div className='GameTileMasonry__Col'>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 0 ]} highRes className='--size2x' />
                          </div>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 8 ]} />
                            <AgGameTile showLabel game={games[ 9 ]} />
                          </div>
                        </div>
                        <div className='GameTileMasonry__Col'>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 1 ]} />
                            <AgGameTile showLabel game={games[ 2 ]} />
                          </div>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 3 ]} />
                            <AgGameTile showLabel game={games[ 4 ]} />
                          </div>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 10 ]} />
                            <AgGameTile showLabel game={games[ 11 ]} />
                          </div>
                        </div>
                        <div className='GameTileMasonry__Col'>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 5 ]} highRes className='--size2x' />
                          </div>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 12 ]} />
                            <AgGameTile showLabel game={games[ 13 ]} />
                          </div>
                        </div>
                        <div className='GameTileMasonry__Col'>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 6 ]} />
                            <AgGameTile showLabel game={games[ 15 ]} />
                          </div>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 7 ]} />
                            <AgGameTile showLabel game={games[ 16 ]} />
                          </div>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 14 ]} />
                            <AgGameTile showLabel game={games[ 18 ]} />
                          </div>
                        </div>
                        <div className='GameTileMasonry__Col'>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 17 ]} highRes className='--size2x' />
                          </div>
                          <div className='GameTileMasonry__Row'>
                            <AgGameTile showLabel game={games[ 19 ]} />
                            <AgGameTile showLabel game={games[ 20 ]} />
                          </div>
                        </div>
                      </>
                    )).EndIf()
                }
              </>
            )).EndIf()
        }
      </div>
    );
  });
