import { createComponent, IntrinsicProps, Slot } from '@/common/util/templateHelpers';
import BlockPod from '@/components/BlockPod';

import GameTileMasonry from '@/components/GameTileMasonry';
import TakeoverContainer from '@/components/TakeoverContainer';
import { SiteTakeover } from '@/models/Takeover';
import { GameBasic } from '@/util/formatters';
import { useState } from 'react';

// import style from './index.module.scss';

interface SiteTakeoverProps extends IntrinsicProps {
  takeover: SiteTakeover
  mobileGames: GameBasic[]
  initialGames: GameBasic[]
  fetchGames: () => Promise<GameBasic[]>
}

export default createComponent<SiteTakeoverProps>('SiteTakeover', 
  {}, 
  function SiteTakeover ({ slots, className }, props) {
    const [ ready, setReady ] = useState(false);
    
    return (
      <TakeoverContainer className={className} takeover={props.takeover}>
        <BlockPod 
          box 
          transparentBg
          style={{ 
            visibility: ready ? 'visible' : 'hidden'
          }}
        >
          <Slot name='title'>{slots.title}</Slot>
          <Slot name='aside'>{slots.aside}</Slot>
          <GameTileMasonry
            mobileGames={props.mobileGames}
            initialGames={props.initialGames}
            onReady={() => setReady(true)}
          />
        </BlockPod>
      </TakeoverContainer>
    );
  });
